<template>
  <!-- /22832329239/Camfrog_Right_Sticky_DWEB -->
  <google-tag gpt-id="div-gpt-ad-1708520047764-0" style="min-width: 160px; min-height: 250px;" />
</template>

<script>
import GoogleTag from "@/components/Ads/GoogleTag";

export default {
  name: "RoomBrowserRightSticky",
  components: { GoogleTag }
}
</script>

<style scoped>
div {
  position: sticky;
  top: 30px;
}
</style>
