<template>
  <div v-if="showAdultSwitch"
       id="adult-filter-container"
       data-testid="adult-switch"
       class="adult-filter-container"
  >
    <InputSwitch id="adult-filter"
                 v-model="adult"
                 v-tippy="{ placement : 'left'}"
                 :content="translateAdult"
                 :disabled="adult_confirm_visible"
                 @change="changeAdult"
    />
    <Dialog id="adult-filter-dialog"
            data-testid="adult-filter-dialog"
            :visible.sync="adult_confirm_visible"
            :closable="false"
            :position="confirmationPos"
    >
      <template #header>
        <h3 v-html="$t('message.family_filter_18_warning')" />
        <div class="close-icon" @click="rejectConfirmation" />
      </template>

      <i18n path="message.family_filter" tag="p" for="message.family_filter_link">
        <button class="link-common" @click="openTermsOfService">
          {{ $t('message.family_filter_link') }}
        </button>
      </i18n>

      <template #footer>
        <Button :label="$t('message.dialog.no')" class="p-button-text" @click="rejectConfirmation()" />
        <Button :label="$t('message.dialog.yes')" @click="acceptConfirmation()" />
      </template>
    </Dialog>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  name: "AdultSwitch",

  props: {
    confirmationPos: {
      type: String,
      required: false,
      default: 'topright',
    },
  },

  data() {
    return {
      adult_confirm_visible: false,
      adult: false,
    }
  },

  computed: {
    ...mapGetters({
      globalFilters: 'globalFilters',
      showAdultSwitch: 'showAdultSwitch',
      enableAdultSwitch: 'enableAdultSwitch',
      currentCategory: 'currentCategory',
    }),

    translateAdult() {
      return this.adult ? this.$t('message.family_filter_off') : this.$t('message.family_filter_on');
    }
  },

  watch: {
    globalFilters: {
      handler: function (newValue) {
        this.adult = newValue.enable_adult_switch
        this.setGlobalFilters(newValue)
      },
      deep: true
    },
  },

  created() {
    this.adult = this.enableAdultSwitch
  },

  methods: {
    ...mapActions({
      setGlobalFilters: 'setGlobalFilters',
      setAdultGlobalFilter: 'setAdultGlobalFilter'
    }),

    /************* Adult switch **************/
    changeAdult() {

      if (this.adult && !this.adult_confirm_visible) {
        this.adult = false;
        this.adult_confirm_visible = true
      } else {
        this.commitChange()
      }
    },

    commitChange() {
      this.setAdultGlobalFilter(this.adult)
      this.$emit('changeAdult')
    },

    acceptConfirmation() {
      this.adult_confirm_visible = false
      this.adult = true
      this.commitChange()
    },

    rejectConfirmation() {
      this.adult_confirm_visible = false
      this.adult = false
    },

    openTermsOfService() {
      this.$roomBrowserService.openTermsOfService();
    },
  }
}
</script>

<style lang="scss">
.adult-filter-container {
  padding-top: 4px;
  #adult-filter {
    height: 22px;
    width: 50px;

    .p-inputswitch-slider {
      background-color: #AEAFB1;
      background-image: url("~@/assets/images/room_browser/icon-adult.svg");
      background-repeat: no-repeat;
      background-position: top 6px right 6px;

      &:before {
        width: 18px;
        height: 18px;
        left: 0.15rem;
        margin-top: -0.575rem;
      }
    }

    &.p-inputswitch-checked .p-inputswitch-slider {
      background-color: #FF705C;
      background-position: top 6px left 6px;

      &:before {
        transform: translateX(1.75rem);
      }
    }
  }
  #adult-filter-dialog {
    max-width: 436px;
  }
}
</style>
