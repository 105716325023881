<template>
  <div id="language-selector-container" class="language-selector-container" @click="setHighlightLangButton(false)">
    <Dropdown id="language-selector"
              v-model="selected_language"
              data-testid="language-selector"
              class="language-selector"
              :class="{ 'highlight': highlightLangButton }"
              :options="languageList"
              option-label="name"
              scroll-height="236px"
              @change="changeLanguage"
    >
      <template #value="slotProps">
        <div class="language-item language-item-value">
          <span class="language-country">{{ slotProps.value.name }}</span>
        </div>
      </template>
      <template #option="slotProps">
        <div class="language-item">
          <span>{{ slotProps.option.name }}</span>
        </div>
      </template>
    </Dropdown>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  name: "LanguageList",

  data() {
    return {
      selected_language: {}
    }
  },

  computed: {
    ...mapGetters({
      languageList: 'languageList',
      currentLanguage: 'currentLanguage',
      highlightLangButton: 'highlightLangButton'
    }),
  },

  watch: {
    currentLanguage: {
      handler: function () {
        this.selected_language = this.currentLanguage
      },
      deep: true
    },
    highlightLangButton: {
      handler: function () {
        setTimeout(() => {
          this.setHighlightLangButton(false)
        }, 9000)
      },
    }
  },

  created() {
    this.selected_language = this.currentLanguage
  },

  methods: {
    ...mapActions({
      setLanguageGlobalFilter: 'setLanguageGlobalFilter',
      setHighlightLangButton: 'setHighlightLangButton'
    }),

    changeLanguage() {
      this.setLanguageGlobalFilter(this.selected_language.id)
      this.$emit('changeLanguage')
    }
  }
}
</script>

<style lang="scss">
.language-selector-container {
  min-width: 151px;
  display: flex;
  #language-selector {
    background-color: #F0F0F0;
    border: 1px solid transparent;
    border-radius: 15px;
    height: 30px;
    width: 100%;

    &:hover {
      border-color: #D8DADF;
    }
    .p-dropdown-trigger {
      display: none;
    }
    .p-highlight {
      background-color: #ECEEF4;
    }
    .p-dropdown-item:hover {
      background-color: #F1F3F7;
    }

    .language-item {
      font-style: normal;
      font-weight: normal;
      font-size: 12px;

      @media screen and (max-width: 700px) {
        font-size: 16px;
      }

      color: #484848;

      > * {
        vertical-align: middle;
      }
      img {
        margin-right: 16px;
      }
    }
    .language-item-value {
      position: relative;
    }
  }
  #language-selector.highlight {
    position: relative;
    border: 1px solid #93FDBA;
    border-radius: 15px;
    background: linear-gradient(0deg, rgba(124, 255, 171, 0.2), rgba(124, 255, 171, 0.2)), #F0F0F0;
  }
  .language-country {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.highlight {
  &:before,
  &:after {
    content: '';
    position: absolute;
    border: 2px solid rgba(147, 253, 186, 0.6);
    left: -6px;
    right: -6px;
    top: -6px;
    bottom: -6px;
    border-radius: 32px;
    opacity: 1;
    animation-name: pulse;
    animation-timing-function: cubic-bezier(0.1, -0.6, 0.2, 0);
    animation-duration: 3s;
    animation-iteration-count: 2;
    z-index: 2;
  }
  &:before {
    opacity: 0;
  }
  &:after {
    opacity: 0;
    animation-delay: 1.5s;
  }
}


@keyframes pulse {
  0% {
    transform: scale(1);
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    transform: scale(1.3);
    opacity: 0;
  }
}
</style>
