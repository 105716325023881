<template>
  <!-- /22832329239/Camfrog_rooms_728x90_DWEB -->
  <google-tag gpt-id="div-gpt-ad-1708519721344-0" style="min-width: 728px; min-height: 90px;" />
</template>

<script>
import GoogleTag from "@/components/Ads/GoogleTag";

export default {
  name: "RoomBrowserTopHorizontal",
  components: { GoogleTag }
}
</script>

<style scoped lang="scss">
  div {
    margin: 0 auto;
  }
</style>
