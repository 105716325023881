<template>
  <div id="rooms-search-container"
       ref="searchContainer"
       class="rooms-search-container"
       data-testid="rooms-search-container"
  >
    <div class="search-field"
         data-testid="search-field"
         :class="{ 'search-active': inputActive }"
         @mouseover="inputActive = true"
         @mouseout="inputActive = false"
    >
      <img class="icon-search" src="@/assets/images/room_browser/icon-search.svg" alt="icon-search">
      <AutoComplete ref="autocompleteVisible"
                    v-model="search_query"
                    class="rooms-search-input"
                    :suggestions="autocomplete_words"
                    :placeholder="$t('message.search.search_room')"
                    maxlength="30"
                    @complete="searchRooms($event)"
                    @item-select="changeSearch(search_query.trimStart().toLowerCase())"
                    @keyup.enter="changeSearch(search_query.trimStart().toLowerCase())"
                    @focus="focusInput"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { roomBrowserMixin } from "@/services/mixins";

export default {
  name: "SearchInput",

  mixins: [ roomBrowserMixin ],

  data() {
    return {
      search_query: '',
      search_chips: [],
      autocomplete_words: [],
      inputActive: false,
      isFocusInput: true,
    }
  },

  computed: {
    ...mapGetters({
      searchResult: 'searchResult'
    }),

    searchData() {
      return this.searchResult || {}
    },
  },

  watch: {
    searchResult: {
      handler: function (newValue) {
        this.search_chips = newValue.search_query || []
      },
      deep: true
    },
    search_query: {
      handler: function (newValue) {
        if (newValue.trim() !== '') {
          this.searchAutocomplete(newValue.trim())
        } else {
          this.isFocusInput = true
          this.focusInput()
        }
      },
      deep: true
    }
  },

  created() {
    this.search_chips = this.searchData && this.searchData.search_query ? this.searchData.search_query : []
  },

  methods: {
    ...mapActions({
      setSearchResult: 'setSearchResult',
      updateSearchChips: 'updateSearchChips'
    }),

    searchRooms(event) {
      if (!event.query.trim().length) {
        this.autocomplete_words = [...this.autocomplete_words];
      }
      else {
        this.autocomplete_words = this.autocomplete_words.filter((word) => {
          return word.toLowerCase().startsWith(event.query.toLowerCase());
        });
      }

    },

    focusInput() {
      /*if (this.search_query === '' && this.isFocusInput) {
        this.searchAutocomplete('')
        return
      }*/ //history temporary disabled
      this.isFocusInput = false
    },

    searchAutocomplete(query) {
      this.$roomBrowserService.getSearchAutocomplete(
          {
            search: query
          },
          (response) => {
            if (response.length) {
              this.autocomplete_words = response
            }
            if (this.autocomplete_words.length && this.$refs.autocompleteVisible) {
              this.$refs.autocompleteVisible.overlayVisible = true
            }
          },
          (response) => {
            console.log('SearchInput.vue. searchAutocomplete. error: ', response);
          }
      )
    },

    changeSearch(word) {
      this.focusInput()

      if (word === '') {
        return
      }

      let findElement = this.search_chips.find(elem => elem === word)
      if (!findElement) {
        this.search_chips.push(word)
      }
      this.updateSearchChips(this.search_chips)

      const path = `search`
      if (this.$route.name !== path) {
        this.$router.push({name: path})
      }

      this.autocomplete_words = []
      this.search_query = ''
    },
  }
}
</script>

<style lang="scss">
.rooms-search-container {
  position: relative;
  flex-grow: 1;

  .p-autocomplete-input {
    width: 100%;
  }
  .p-autocomplete-panel {
    top: 32px !important;
    font-size: 12px;
    line-height: 16px;
    color: #484848;

    @media screen and (max-width: 700px) {
      font-size: 16px;
    }
  }
  .p-inputtext {
    font-size: 12px;
    background: #f0f0f0;
    padding: 0 0 0 3px;
    border-radius: 15px;
    border: none;

    @media screen and (max-width: 700px) {
      font-size: 16px;
    }
  }
  img.icon-search {
    position: absolute;
    z-index: 1;
    top: 3px;
    left: 8px;
  }
  .rooms-search-input {
    height: 30px;
    width: 100%;
    background-color: #F0F0F0;
    box-sizing: border-box;
    padding: 5px 40px 5px 37px;
    font-size: 12px;
    font-family: 'Open Sans', sans-serif;
    outline: none;
    -webkit-appearance: none;
    transition: .25s;
    border-radius: 15px;
    border: none;
    &:hover {
      background: #F7F7F7;
      .p-inputtext {
        background: #F7F7F7;
      }
      //border: 1px solid #D8DADF;
    }
    &:active {
      background: #F7F7F7;
    }
  }
  .search-field {
    border: 1px solid transparent;
  }
  .search-active {
    border-radius: 20px;
    border: 1px solid #D8DADF;
  }
  .autocomplete-field {
    width: 100%;
    display: block;
    position: absolute;
    z-index: 102;
    background-color: #fff;
    top: 35px;
    padding: 5px;
    border: 1px solid #c8c8c8;
    border-radius: 3px;
    height: auto;
    .word-wrapper {
      padding: 7px 0;
      &:hover {
        cursor: pointer;
        background-color: #cae5fc;
      }
    }
  }
}
</style>
