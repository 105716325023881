<template>
  <div class="room-browser-web">
    <div v-if="showLoader" class="loader-wrapper">
      <Loader />
    </div>
    <div v-show="!showLoader" class="ads ads-horizontal">
      <room-browser-top-horizontal />
    </div>
    <div v-show="!showLoader" class="room-browser-web-content">
      <div class="ads ads-vertical">
        <room-browser-left-sticky />
      </div>
      <div ref="container" class="room-browser-web-container" data-testid="room-browser-web-container">
        <room-browser-web-header data-testid="header" @reload="reload" />
        <router-view :is-website="true" />
      </div>
      <div class="ads ads-vertical">
        <room-browser-right-sticky />
      </div>
    </div>
  </div>

</template>

<script>
import { EventBus } from "@/event_bus";
import { mapActions, mapGetters } from 'vuex'
import RoomBrowserWebHeader from "../../components/RoomBrowserWebHeader";
import Loader from "@/components/Loader";
import RoomBrowserLeftSticky from "@/components/Ads/RoomBrowser/RoomBrowserLeftSticky.vue";
import RoomBrowserRightSticky from "@/components/Ads/RoomBrowser/RoomBrowserRightSticky.vue";
import RoomBrowserTopHorizontal from "@/components/Ads/RoomBrowser/RoomBrowserTopHorizontal.vue";

export default {
  name: "RoomBrowserWeb",

  components: {
    RoomBrowserTopHorizontal,
    RoomBrowserRightSticky,
    RoomBrowserLeftSticky,
    Loader,
    RoomBrowserWebHeader
  },

  computed: {
    ...mapGetters({
      showLoader: 'showLoader',
      globalParams: 'globalParams',
    }),
  },

  created() {
    EventBus.$on('setGlobalParams', this.setGlobalsConfig);
    EventBus.$on('setLanguagesList', this.setLanguageFilter);
    EventBus.$on('changeFollowed', response => this.changeFollowed(response));
    EventBus.$on('reloadRoomBrowser', this.reload);
    EventBus.$on('highlightUI', response => this.highlightUI(response));
    this.$roomBrowserService.initialize();
  },

  methods: {
    ...mapActions({
      updateParamsInit: 'updateParamsInit',
      setGlobalParams: 'setGlobalParams',
      setLanguagesList: 'setLanguagesList',
      setLanguageGlobalFilter: 'setLanguageGlobalFilter',
      setHighlightLangButton: 'setHighlightLangButton',
      updateLoader: 'updateLoader',
    }),

    setGlobalsConfig(data) {
      /* Updated global parameters */
      this.setGlobalParams(data)

      /* Updated internationalization local */
      this.$i18n.locale = this.globalParams.client_language;

      this.updateParamsInit(true)
      this.reload(true)
    },

    setLanguageFilter(filter) {
      this.setLanguagesList(filter.list)
      this.setLanguageGlobalFilter(filter.value)
    },

    reload(is_initial) {
      this.updateLoader(true)
      this.$root.$refs.currentComponent.load(is_initial)
    },

    changeFollowed(data) {
      this.$root.$refs.currentComponent.changeFollowed(data)
    },
  }
}
</script>

<style scoped lang="scss">
@import url('https://fonts.googleapis.com/css?family=Open+Sans:300,300i,400,400i,600,600i,700,700i,800,800i&display=swap&subset=cyrillic,cyrillic-ext,latin-ext,vietnamese');
@import "../../../style/reset.css";

.room-browser-web {
  margin: 67px auto 0;
  min-height: 500px;
  min-width: 360px;
  position: relative;

  .room-browser-web-content {
    //position: relative;
    display: flex;
    justify-content: space-evenly;
  }

  .ads-horizontal {
    margin: 15px auto;

    & > div {
      @media screen and (max-width: 540px) {
        display: none;
      }
    }
  }

  .ads-vertical > div {
    @media screen and (max-width: 1024px) {
      display: none;
    }
  }

  .room-browser-web-container {
    min-width: 300px;
    max-width: 960px;
    width: 71.5%;
    display: flex;
    flex-direction: column;

    @media screen and (max-width: 1345px) {
      width: 799px;
    }
    @media screen and (max-width: 1130px) {
      width: 637px;
    }
    @media screen and (max-width: 700px) {
      width: 480px;
    }
    @media screen and (max-width: 540px) {
      width: 315px;
    }

    #room-browser-home {
      .btn-banners-container {
        .btn-banner {
          width: 176px;
        }
      }
    }
  }
}
</style>
