<template>
  <div class="room-browser-web-header">
    <i18n path="videochat.rb_header_title" tag="div" class="header-title">
      <template v-slot:rooms_count>
        <span>{{ totalFormatted }}</span>
      </template>
    </i18n>
    <i18n
      path="videochat.rb_subtitle"
      tag="div"
      for="videochat.rb_subtitle_link"
      class="header-subtitle"
    >
      <button class="link-common" @click="startRoom">
        {{ $t('videochat.rb_subtitle_link') }}
      </button>
    </i18n>
    <div class="header-bar" :class="{'adult-show': showAdultSwitch}">
      <!--  Search input -->
      <search-input @reloadData="reloadData" />
      <!--  Language dropdown  -->
      <LanguageList v-if="!isHomePage" @changeLanguage="reloadData" />
      <!--  Adult switch  -->
      <AdultSwitch confirmation-pos="top" @changeAdult="reloadData" />
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { localeFormat } from "@/services/number_formatter";
import SearchInput from "../../room_browser/components/search/SearchInput";
import LanguageList from "../../room_browser/components/LanguageList";
import AdultSwitch from "../../room_browser/components/AdultSwitch";
import {roomBrowserMixin} from "@/services/mixins";

export default {
  name: "RoomBrowserWebHeader",

  components: {
    SearchInput,
    LanguageList,
    AdultSwitch
  },

  mixins: [ roomBrowserMixin ],

  computed: {
    ...mapGetters({
      totalRooms: 'totalRooms',
      showAdultSwitch: 'showAdultSwitch'
    }),
    totalFormatted() {
      return localeFormat(this.totalRooms)
    }
  },

  methods: {
    reloadData() {
      this.$emit('reload')
    },
    startRoom() {
      this.$roomBrowserService.actionRoomStart()
    }
  },

}
</script>


<style lang="scss">
.header-title {
  margin-bottom: 15px;
  text-align: center;
  font-weight: 300;
  font-size: 36px;
  line-height: 48px;
  color: #363636;
  span {
    color: #46BF64;
  }
}

.header-subtitle {
  margin-bottom: 50px;
  text-align: center;
  font-weight: 300;
  font-size: 20px;
  line-height: 27px;
  color: #363636;
}
.header-bar {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 10px;

  .rooms-search-container img.icon-search {
    width: 35px;
  }

  .p-dropdown-label {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .adult-filter-container {
    padding-top: 0;
    #adult-filter {
      width: 81px;
      .p-inputswitch-slider {
        background-position: top 11px right 11px;
        background-size: 28px;
        background-image: url("~@/assets/images/room_browser/icon-adult-web.svg");
        &:before {
          width: 28px;
          height: 28px;
          left: 0.35rem;
          top: 37%;
        }
      }
      &.p-inputswitch-checked .p-inputswitch-slider {
        background-color: #F96771;
        background-position: top 11px left 11px;
      }
      &.p-inputswitch-checked .p-inputswitch-slider:before {
        -webkit-transform: translateX(2.65rem);
        transform: translateX(2.65rem);
      }
    }

  }

  .language-selector-container {
    #language-selector {
      color: #4A4A4A;
      font-size: 16px;
    }
  }

  .rooms-search-input,
  #language-selector,
  #adult-filter {
    height: 38px;
    border-radius: 19px;
  }
}

@media screen and (max-width: 540px) {
  .room-browser-web-header {
    & > .header-bar {
      display: grid;
      grid-auto-flow: dense;
      grid-gap: 10px;
      grid-template-columns: 1fr;
      grid-template-rows: 1fr;

      .language-selector-container {
        grid-row: 1;
        grid-column: span 2;
      }

      .rooms-search-container {
        grid-column: span 2;
        width: 100%;
      }

      &.adult-show {
        .language-selector-container {
          grid-row: 1;
          grid-column: 1;
        }
        .adult-filter-container {
          grid-row: 1;
          grid-column: 2;
        }
      }
    }
  }
}
</style>
