export const localeFormat = (number, locales = 'en-US') => {
  return (number * 1).toLocaleString(locales)
}

export const rewardPointsFormat = (float) => {
  let e = 4;

  if (float > 0) {
    while (float * Math.pow(10, e) < 1) {
      e++;
    }
  }

  return (Math.floor(float * Math.pow(10, e)) / Math.pow(10, e)).toFixed(e);
}
